import React, { createContext } from 'react';
import PropTypes from 'prop-types';

export const GatewayContext = createContext(null);

export const GatewayContextProvider = ({ children, value }) => {
    return <GatewayContext.Provider value={{ value }}>{children}</GatewayContext.Provider>;
};

GatewayContextProvider.propTypes = {
    children: PropTypes.node.isRequired,
    value: PropTypes.shape({
        isLoading: PropTypes.bool.isRequired,
    }).isRequired,
};
